import './bootstrap';

const select2 = require('select2');
window.select2 = select2;
$('select').select2();

const moment = require("moment");
window.moment = moment;
window.swal = require('sweetalert2')
window.dropify = require('dropify')
